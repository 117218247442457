import { AuthService, FetchConfig }                       from 'aurelia-auth';
import { Aurelia, bindable, inject, PLATFORM, transient } from 'aurelia-framework';
import { BaseViewModel }                                  from 'base-view-model';
import { ConsentsRepository }                             from 'modules/administration/consents/services/repository';
import { UsersRepository }                                from 'modules/administration/users/services/repository';
import { AureliaAuthConfig }                              from 'resources/configs/aurelia-auth-config';
import { RouterConfig }                                   from 'resources/configs/router-config';
import { AppContainer }                                   from 'resources/services/app-container';
import environment                                        from '../config/environment.json';

@transient()
@inject(Aurelia, AppContainer, AuthService, FetchConfig, RouterConfig, UsersRepository, ConsentsRepository)
export class Consents extends BaseViewModel {

    @bindable checkedConsents = [];

    /**
     * Constructor
     *
     * @param aurelia
     * @param appContainer
     * @param authService
     * @param fetchConfig
     * @param routerConfig
     * @param usersRepository
     * @param consentsRepository
     */
    constructor(aurelia, appContainer, authService, fetchConfig, routerConfig, usersRepository, consentsRepository) {
        super(appContainer);

        this.aurelia            = aurelia;
        this.authService        = authService;
        this.fetchConfig        = fetchConfig;
        this.routerConfig       = routerConfig;
        this.usersRepository    = usersRepository;
        this.consentsRepository = consentsRepository;
        this.environment        = environment;
    }

    /**
     * Handles activate event
     *
     * @param params
     */
    async activate(params) {
        super.activate(params);

        this.fetchConfig.configure();
        this.routerConfig.configure();

        this.authenticatedUser = await this.appContainer.authenticatedUser.fetchAuthenticatedUser();

        this.consents = await this.consentsRepository.active();

        this.checkedConsents.splice(0, this.checkedConsents.length, ...this.authenticatedUser.consents.map(consent => consent.id));
    }

    /**
     * Runs after view is attached
     */
    attached() {
        document.body.className = 'login-container';

        $('.form-check-input-styled').uniform();
    }

    back() {
        this.authService.logout(AureliaAuthConfig.logoutRedirect)
            .then(() => this.aurelia.setRoot(PLATFORM.moduleName('app-offline')))
            .catch(() => console.error('Error logging out'));
    }

    /**
     * Submits the form
     */
    submit() {
        this.usersRepository
            .updateConsents({ consents: this.checkedConsents })
            .then((response) => {
                if (response.token) {
                    // set the default token to start session
                    this.appContainer.localStorage.set('aurelia_token', response.token);

                    this.appContainer.router.navigate('/', { replace: true, trigger: false });

                    this.aurelia.setRoot(PLATFORM.moduleName('app'));
                } else {
                    // show some kind of notification!
                    this.appContainer.notifier.dangerNotice(response.message);
                }
            });
    }
}
